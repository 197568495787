body{
  height: 100vh;
  background:#10151e;
  font-family:微軟正黑體!important;
}
.terms-list{
  
}
.terms-list li{
  margin-bottom: 10px;
}
$bgcolor : #10151e;
.title-light01{
  color:#a6becf;
}
.bg-dark{
  background: $bgcolor !important;
}
.logoWord{
  text-transform: uppercase;
  font-weight: 800;
  font-family: sans-serif;
  text-decoration: none;
  span{
    color:#7881ed;
  }
}

.cards{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap:wrap;
  li{

    // cursor: pointer;
    position: relative;
    background: none;
    .cover{
      -webkit-box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.61); 
      box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.61);
      width: 263px;
      height: 263px;
      background: rgb(92, 92, 92);
      margin-bottom: 10px;
      position: relative;
      border-radius: 5px;
      &::after{
        content:'';
        border-radius: 5px;
        width: 100%;
        height: 100%;
        background: #58d6ff;
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.3s;
        transform: translateY(0px);
      }
      &:before{
        content:'';
        border-radius: 5px;
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgb(88,214,255);
        background: linear-gradient(180deg, rgba(88,214,255,0) 45%, rgba(88,214,255,1) 100%);
        top: 0;
        left: 0;
        transition: 0.3s;
        opacity: 0;
        z-index: 6;
      }
      &:hover{
        &:after{
          transform: translateY(2px);
        }
        &::before{
          opacity: 0.4;
        }
        .goExplore{
          opacity: 1;
        }
      }
      img{
        border-radius: 5px;
        position: relative;
        width: 100%;
        z-index: 5;
      }
    }
    .goExplore{
      position: absolute;
      z-index: 7;
      color:#58d6ff;
      top: 82%;
      left: 50%;
      transform: translateX(-50%);
      border:#58d6ff 2px solid;
      padding:5px 10px;
      border-radius: 50px;
      font-size: 13px;
      font-weight: 800;
      opacity: 0;
      transition: 0.3s;
    }
    .title{
      color:#999;
    }
    
  }
}
.loginNav{
  a{
    text-decoration: none;
    color: white;
    margin-left: 10px;
    font-size: 17px;
    font-weight: 800;
    &:hover{
      color:#b1b4b6;
    }

  }
}
footer{
  .footerLink{
    text-decoration: none;
    color:#4f5d74;
    border-bottom: 1px solid #4f5d74;
    margin-right: 15px;
    padding-bottom: 5px;
    &:hover{
      color:white;
    }
  }
}